// src/components/CategoryProgressBars.js
import React from 'react';
import { Box, Typography, LinearProgress } from '@mui/material';

const CategoryProgressBars = ({ categoryExpenses }) => {
  if (!categoryExpenses || categoryExpenses.length === 0) {
    return (<Typography variant="subtitle1" sx={{ mt: 2 }}>
        No category expenses found for this month.
      </Typography>);
  }

  return (<Box sx={{ mt: 2 }}>
      {categoryExpenses.map((item) => {
        const spent = item.total_spent || 0;
        const allotted = item.allotted_amount || 0;
        // Calculate the percentage (avoiding division by zero)
        const percentage = allotted > 0 ? (spent / allotted) * 100 : 0;

        return (<Box key={item.category_id} sx={{ mb: 3 }}>
            <Typography variant="body1">
              {item.category_name}: {spent.toFixed(2)} / {allotted.toFixed(2)}
            </Typography>
            <LinearProgress
              variant="determinate"
              value={percentage}
              sx={{ height: 8, borderRadius: 4, mt: 1 }}
            />
          </Box>);
      })}
    </Box>);
};

export default CategoryProgressBars;
