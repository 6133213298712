// src/App.js

import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { Container, Typography, createTheme, ThemeProvider } from '@mui/material';
import Login from './pages/Login';
import Home from './pages/Home'; // The main expense tracker page
import { AuthProvider, useAuth } from './contexts/AuthContext';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2', // Customize your primary color
    }, secondary: {
      main: '#dc004e', // Customize your secondary color
    },
  },
});

// ProtectedRoute Component
const ProtectedRoute = ({ children }) => {
  const { isAuthenticated } = useAuth();
  return isAuthenticated ? children : <Navigate to="/login" replace/>;
};

function App() {
  return (<ThemeProvider theme={theme}>
    <AuthProvider>
      <Container maxWidth="lg" sx={{ mt: 5, mb: 5 }}>
        <Typography variant="h4" component="h1" gutterBottom align="center">
          Expense Tracker
        </Typography>
        <Routes>
          <Route path="/login" element={<Login/>}/>
          <Route
            path="/"
            element={<ProtectedRoute>
              <Home/>
            </ProtectedRoute>}
          />
          {/* Redirect any unknown routes to home or login */}
          <Route path="*" element={<Navigate to="/" replace/>}/>
        </Routes>
      </Container>
      <ToastContainer/>
    </AuthProvider>
  </ThemeProvider>);
}

export default App;
