// src/pages/Home.js
import React, { useState, useEffect, useCallback } from 'react';
import { Box, Typography, Button } from '@mui/material';
import YearTabs from '../components/YearTabs';
import MonthTabs from '../components/MonthTabs';
import ExpensesList from '../components/ExpensesList';
import ScreenshotUpload from '../components/ScreenshotUpload';
import CategoryProgressBars from '../components/CategoryProgressBars'; // <-- import new component
import axiosInstance from '../api/axiosInstance';
import { useAuth } from '../contexts/AuthContext';
import { toast } from 'react-toastify';

const Home = () => {
  const { logout, isAuthenticated } = useAuth();

  // Same states as before
  const [selectedYear, setSelectedYear] = useState(() => {
    const currentYear = new Date().getFullYear().toString();
    return ['2023', '2024', '2025'].includes(currentYear) ? currentYear : '2024';
  });
  const [selectedMonth, setSelectedMonth] = useState(() => {
    const currentMonth = new Date().getMonth() + 1;
    return currentMonth;
  });
  const [expenses, setExpenses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // NEW: State to hold category expenses
  const [categoryExpenses, setCategoryExpenses] = useState([]);
  const [catLoading, setCatLoading] = useState(false);
  const [catError, setCatError] = useState(null);

  const fetchExpenses = useCallback(async () => {
    setLoading(true);
    setError(null);
    setExpenses([]);

    try {
      const response = await axiosInstance.get(`get_expenses/${selectedMonth}/${selectedYear}/`);
      setExpenses(response.data);
    } catch (err) {
      console.error(err);
      setError('Failed to fetch expenses. Please try again later.');
      toast.error('Failed to fetch expenses. Please try again later.');
    } finally {
      setLoading(false);
    }
  }, [selectedMonth, selectedYear]);

  // NEW: Function to fetch category-expenses data
  const fetchCategoryExpenses = useCallback(async () => {
    setCatLoading(true);
    setCatError(null);
    setCategoryExpenses([]);

    try {
      // Example endpoint: 'category_expenses/1/2025/'
      // Adapt it to your actual route (like 'category_expenses/${selectedMonth}/${selectedYear}/')
      const response = await axiosInstance.get(`category_expenses/${selectedMonth}/${selectedYear}/`);
      setCategoryExpenses(response.data);
    } catch (err) {
      console.error(err);
      setCatError('Failed to fetch category expenses. Please try again later.');
      toast.error('Failed to fetch category expenses. Please try again later.');
    } finally {
      setCatLoading(false);
    }
  }, [selectedMonth, selectedYear]);

  useEffect(() => {
    if (isAuthenticated) {
      fetchExpenses();
      fetchCategoryExpenses(); // Fetch category expenses too
    }
  }, [selectedYear, selectedMonth, isAuthenticated, fetchExpenses, fetchCategoryExpenses]);

  const handleYearChange = (event, newValue) => {
    setSelectedYear(newValue);
  };

  const handleMonthChange = (event, newValue) => {
    setSelectedMonth(newValue);
  };

  const handleUploadSuccess = () => {
    // Refresh data after uploading screenshot
    fetchExpenses();
    fetchCategoryExpenses();
  };

  return (<Box>
      {/* User Info and Logout Button */}
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h6">Welcome!</Typography>
        <Button variant="outlined" color="secondary" onClick={logout}>
          Logout
        </Button>
      </Box>

      {/* Year and Month Tabs */}
      <YearTabs selectedYear={selectedYear} handleYearChange={handleYearChange}/>
      <MonthTabs selectedMonth={selectedMonth} handleMonthChange={handleMonthChange}/>

      {/* NEW: Category Progress Bars - placed "at the top" */}
      <CategoryProgressBars categoryExpenses={categoryExpenses}/>

      {/* Screenshot Upload */}
      <ScreenshotUpload onUploadSuccess={handleUploadSuccess}/>

      {/* Expenses List */}
      <ExpensesList
        expenses={expenses}
        loading={loading}
        error={error}
        fetchExpenses={fetchExpenses} // Pass fetchExpenses as a prop
      />
    </Box>);
};

export default Home;
